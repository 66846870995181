.footer {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.footer .top {
  grid-template-columns: 1fr 1fr 1fr 2fr;
  grid-template-areas: "item1 item2 item3 articles";
  width: 100%;
  max-width: 1200px;
  display: grid;
}

.footer .top a {
  color: #505565;
  margin-bottom: 12px;
  text-decoration: none;
  display: block;
}

.footer .top a:hover {
  color: #7187fb;
}

.footer .top a.mail {
  color: #536ced;
}

.footer .top .title {
  font-family: CirceBold;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-weight: initial;
  -webkit-font-smoothing: antialiased;
  color: #232735;
  margin-bottom: 12px;
}

.footer .top .items {
  flex-direction: column;
  display: flex;
}

.footer .top :first-child {
  grid-area: item1;
}

.footer .top :nth-child(2) {
  grid-area: item2;
}

.footer .top :nth-child(3) {
  grid-area: item3;
}

.footer .top :nth-child(4) {
  grid-area: articles;
}

.footer .bottom {
  color: #8b90a0;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-top: 84px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  position: relative;
}

.footer .bottom .copyRight {
  margin-right: 16px;
}

.footer .bottom .links {
  flex-wrap: wrap;
  gap: 8px;
  display: flex;
}

.footer .bottom .links a {
  color: #8b90a0;
  white-space: nowrap;
  text-decoration: none;
}

.footer .bottom .links a:first-of-type {
  margin-left: 0;
}

.footer .bottom .links a:not(:last-of-type):after {
  content: "|";
  color: #8b90a0;
  margin-left: 8px;
}

.footer .bottom .links a:hover {
  color: #7187fb;
}

.footer .bottom .social {
  gap: 12px;
  display: flex;
  position: absolute;
  right: 0;
}

.footer .bottom .social img {
  display: block;
}

@media only screen and (width <= 1400px) {
  .footer .top, .footer .bottom {
    max-width: 960px;
  }
}

@media only screen and (width <= 1280px) {
  .footer {
    padding: 40px 0;
  }

  .footer .top, .footer .bottom {
    padding: 0 16px;
  }

  .footer .top .social, .footer .bottom .social {
    right: 16px;
  }

  .footer .top {
    grid-row-gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "item1 item2 item3"
                         "articles articles articles";
  }
}

@media only screen and (width <= 768px) {
  .footer .top, .footer .bottom {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 20px;
    padding: 0 16px;
  }

  .footer .top {
    grid-row-gap: 32px;
    grid-template-columns: 1fr;
    grid-template-areas: "item1"
                         "item2"
                         "item3"
                         "articles";
  }

  .footer .top a {
    margin-bottom: 16px;
  }

  .footer .bottom {
    margin-top: 52px;
  }

  .footer .bottom .social {
    bottom: -5px;
  }

  .footer .bottom .links {
    flex-direction: column;
  }

  .footer .bottom .links a:after {
    display: none;
  }

  .footer .bottom:after {
    content: "";
    background: #eceef3;
    width: 100%;
    height: 1px;
    margin-bottom: 12px;
  }
}

@media only screen and (width <= 480px) {
  .footer {
    padding: 32px 0;
  }

  .footer .top, .footer .bottom {
    padding: 0 16px;
  }
}
/*# sourceMappingURL=footer.f4b8bd78.css.map */
