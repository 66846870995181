@use "../../public/styles/variables.scss";

$max-width: 1200px;

.footer {
  background-color: variables.$ui_01;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 40px;
  @include variables.P3;

  .top {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    grid-template-areas: 'item1 item2 item3 articles';
    max-width: $max-width;

    a {
      text-decoration: none;
      color: variables.$text_02;
      display: block;
      margin-bottom: 12px;

      &:hover {
        color: variables.$linkLight;
      }

      &.mail {
        color: variables.$link;
      }
    }

    .title {
      @include variables.P4;
      @include variables.Bold;
      color: variables.$text_01;
      margin-bottom: 12px;
    }

    .items {
      display: flex;
      flex-direction: column;
    }

    :nth-child(1) {
      grid-area: item1;
    }
    :nth-child(2) {
      grid-area: item2;
    }
    :nth-child(3) {
      grid-area: item3;
    }
    :nth-child(4) {
      grid-area: articles;
    }
  }

  .bottom {
    width: 100%;
    align-items: center;
    max-width: $max-width;
    color: variables.$text_03;
    margin-top: 84px;
    display: flex;
    position: relative;
    @include variables.P4;

    .copyRight {
      margin-right: 16px;
    }

    .links {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      a {
        text-decoration: none;
        color: variables.$text_03;
        white-space: nowrap;

        &:first-of-type {
          margin-left: 0;
        }

        &:not(:last-of-type)::after {
          content: '|';
          color: variables.$text_03;
          margin-left: 8px;
        }

        &:hover {
          color: variables.$linkLight;
        }  
      }
    }

    .social {
      display: flex;
      gap: 12px;
      position: absolute;
      right: 0;

      img {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .footer .top,
  .footer .bottom {
    max-width: 960px;
  }
}

@media only screen and (max-width: 1280px) {
  .footer {
    padding: 40px 0;
  }

  .footer .top,
  .footer .bottom {
    padding: 0px 16px;

    .social {
      right: 16px;
    }
  }

  .footer .top {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'item1 item2 item3' 'articles articles articles';
    grid-row-gap: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .footer .top,
  .footer .bottom {
    padding: 0px 16px;
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 20px;
  }

  .footer .top {
    grid-template-columns: 1fr;
    grid-template-areas: 'item1' 'item2' 'item3' 'articles';
    grid-row-gap: 32px;

    a {
      margin-bottom: 16px;
    }
  }

  .footer .bottom {
    margin-top: 52px;

    .social {
      bottom: -5px;
    }

    .links {
      flex-direction: column;

      a::after {
        display: none;
      }
    }

    &::after {
      height: 1px;
      content: '';
      background: variables.$ui_02;
      width: 100%;
      margin-bottom: 12px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .footer {
    padding: 32px 0;
  }

  .footer .top,
  .footer .bottom {
    padding: 0px 16px;
  }
}